import classNames from 'classnames'
import { Editor } from '/@editor'
import { Caption } from '../caption'
import { ElementError } from '../error'
import { FormEditorProps } from './types'
import isEmpty from 'lodash/isEmpty'
import { vars } from '/@theme/theme.css'
import { ForwardedRef, forwardRef } from 'react'
import { PureEditorContent } from '@tiptap/react'

const FormEditor = forwardRef(
  (props: FormEditorProps, forwardedRef: ForwardedRef<PureEditorContent>) => {
    const { className, caption, errors, showErrorBoard, ...restProps } = props

    const classes = classNames('pq-form__editor', className)

    const editorBorderColor =
      !isEmpty(errors) && !!errors[restProps?.name]
        ? vars.color.error
        : undefined

    return (
      <div className={classes}>
        {caption && <Caption label={caption} />}
        <Editor
          {...restProps}
          borderColor={editorBorderColor}
          ref={forwardedRef}
        />
        <ElementError
          errors={errors}
          name={restProps?.name}
          showErrorBoard={showErrorBoard}
        />
      </div>
    )
  },
)

export { FormEditor }
